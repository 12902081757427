import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Social = () => (
  <StaticQuery
    query={graphql`
      query {
        allInstaNode(sort: {order: DESC, fields: [timestamp]}, filter: { username: { eq: "theedgefilm2019" } }) {
          edges {
            node {
              id
              localFile {
                childImageSharp {
                  fluid(quality: 70, maxWidth: 600, maxHeight: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="c-social c-social--nomargin-top">
        <div className="container">
          <div className="row">
            <div className="c-social__social-link-container">
              <ul className="c-social__social-link-list">
                <li className="c-social__social-link-item">
                  <a className="c-social__social-link c-social__social-link--twitter" href="https://twitter.com/theedgefilm2019" target="_blank">
                    <span className="c-social__social-link-text">Twitter</span>
                    <i className="c-social__social-link-icon icon-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="c-social__social-link-item">
                  <a className="c-social__social-link c-social__social-link--facebook" href="https://www.facebook.com/theedgefilm2019" target="_blank">
                    <span className="c-social__social-link-text">Facebook</span>
                    <i className="c-social__social-link-icon icon-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li className="c-social__social-link-item">
                  <a className="c-social__social-link c-social__social-link--instagram" href="https://www.instagram.com/theedgefilm2019/" target="_blank">
                    <span className="c-social__social-link-text">Instagram</span>
                    <i className="c-social__social-link-icon icon-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="c-social__content-container">
              <div className="cms-content">
                <p>
                  We will be posting exclusive film content and never before-seen footage regularly to all our social media channels.
                  Join us on our journey to the release of the film on the 22nd of July and beyond into the cricketing summer.
                  Follow us for a look behind the scenes and extra insight into the film, the cast, the crew, and the game of cricket.
                  Sign up via email through our website to also receive bonus content, early access to exclusive videos and more.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="c-social__twitter">
              <a className="twitter-timeline" data-theme="dark" href="https://twitter.com/TheEdgeFilm2019?ref_src=twsrc%5Etfw"></a>
            </div>

            <div className="c-social__instagram">
              <div className="row">
                {data.allInstaNode.edges.map(item => {
                  return (
                    <div className="c-social__instagram-item" key={item.node.id}>
                      <a href={`https://www.instagram.com/p/${ item.node.id }`} target="_blank">
                        <Img fluid={item.node.localFile.childImageSharp.fluid} />
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default Social
