import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import Social from '../components/social/social'
import Vendor from '../components/vendor/vendor'

class FollowUsPage extends React.Component {
  render () {
    return (
      <Layout>
        <SEO
          title="Follow Us"
          description="Follow us for a look behind the scenes and extra insight into the film, the cast, the crew, and the game of cricket"
        />
        <article>
          <div className="c-masthead c-masthead--center">
            <div className="container">
              <div className="row">
                <div className="c-masthead__title-container">
                  <h1 className="c-masthead__title">Follow Us</h1>
                </div>
              </div>
            </div>
          </div>
          <Social/>
          <Vendor className="c-vendor--inpage-light" />
        </article>
      </Layout>
    )
  }
}

export default FollowUsPage
